import React, { useEffect, useState, useRef } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  bankAccountListStart,
} from "../../store/slices/WithdrawSlice";
import { importPayoutRequestStart } from "../../store/slices/PayoutSlice";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";
import { useDropzone } from "react-dropzone";
import { withdrawalTransactionsStart } from "../../store/slices/PayoutSlice";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import {
  PAY_OUT_TYPE_ZYRO,
  PAY_OUT_TYPE_AXIS,
  PAY_OUT_TYPE_HAODA_BANK,
  PAY_OUT_TYPE_V2C,
  PAY_OUT_TYPE_DIASPAY
} from "../Constants/constant";

const WithdrawalRequest = (props) => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const t = useTranslation("request_deposit");
  const profile = useSelector((state) => state.admin.profile);
  const bankAccountList = useSelector(
    (state) => state.withdraw.bankAccountList
  );
  const requestPayOut = useSelector((state) => state.withdraw.requestPayOut);
  const importrequestPayOut = useSelector((state) => state.payout.importPayoutRequest);

  const [bankAccounts, setBankAccounts] = useState([]);
  const [skipRender, setSkipRender] = useState(true);
  const [files, setFiles] = useState([]);

  const validationSchema = Yup.object().shape({
    import_excel_file: Yup.mixed().required(t("file.required")),
  });

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(bankAccountListStart());
    }
  }, [profile]);


  useEffect(() => {
    if (
      !bankAccountList.loading &&
      Object.keys(bankAccountList.data).length > 0 &&
      bankAccountList.data.bank_accounts.length > 0
    ) {
      let acc = bankAccountList.data.bank_accounts.map((acc) => ({
        label: acc.account_holder_name + " - " + acc.account_number.slice(-4),
        value: acc.id,
      }));
      setBankAccounts(acc);
    }
  }, [bankAccountList]);

  const handleSubmit = (values) => {
    dispatch(importPayoutRequestStart({ ...values, is_central: 1, import_excel_file: values.import_excel_file[0] }));
  };

  useEffect(() => {
    if (!skipRender && !importrequestPayOut.loading && Object.keys(importrequestPayOut.data).length > 0) {
      dispatch(
        withdrawalTransactionsStart({
          skip: 0,
          take: 12,
          is_central: 1
        })
      );
      props.closeWithdrawalModal()
    }

  }, [importrequestPayOut]);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    accept: {
      "file/xls": [".xls"],
      "file/xlsx": [".xlsx"],
      "file/csv": [".csv"],
    },
    maxFiles: 1,
    useFsAccessApi: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )
    },
  });

  useEffect(() => {
    if (
      !skipRender &&
      !requestPayOut.loading &&
      Object.keys(requestPayOut.data).length > 0
    ) {
      props.closeRequestPayoutModal();
    }
    setSkipRender(false);
  }, [requestPayOut]);

  const removeFile = (fileIndex) => {
    const updatedFiles = files.filter((_, index) => index !== fileIndex);
    setFiles(updatedFiles);
  };

  return (
    props.WithdrawalModal && (
      <>
        <Modal
          className="modal-dialog-center payment-link-created-modal"
          size="sm"
          centered
          show={props.WithdrawalModal}
          onHide={props.closeWithdrawalModal}
          backdrop="static"
        >
          <Modal.Body>
            <Button
              onClick={() => props.closeWithdrawalModal()}
              className="modal-close"
            >
              <Image
                className="close-icon"
                src={window.location.origin + "/img/close-modal-icon.svg"}
              />
            </Button>
            <div className="request-payout-sec">
              <h3>{t("request_payout")}</h3>
              <Formik
                initialValues={{
                  import_excel_file: null,
                }}
                validationSchema={validationSchema}
                innerRef={formRef}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, touched, errors }) => (
                  <FORM >
                    <Form.Group>
                    <Form.Label>{t("paytment_screenshot")}</Form.Label>
                    {files.length <= 0 && (
                      <div className="withdrawal-logo-sec">
                        <div
                          className={`withdrawal-logo-sec-box ${
                            isDragActive ? "active" : ""
                          } ${isDragReject ? "reject" : ""}`}
                          {...getRootProps({
                            onDrop: (e) => {
                              let ext = e.dataTransfer.files[0].name.slice(
                                e.dataTransfer.files[0].name.lastIndexOf(".")
                              );
                              if (
                                e.dataTransfer.files.length == 1 && getInputProps().accept.includes(ext) && e.dataTransfer.files[0].type)
                                setFieldValue("import_excel_file", e.dataTransfer.files);
                              else if (!getInputProps().accept.includes(ext))
                                getErrorNotificationMessage(t("file.invalid"));
                              else
                                getErrorNotificationMessage(t("file.single"));
                            },
                            onChange: (e) => {
                              let ext = e.target.files[0].name.slice((e.target.files[0].name.lastIndexOf(".")));
                              if (e.target.files.length == 1 && getInputProps().accept.includes(ext))
                                setFieldValue("import_excel_file", e.target.files) 
                              else if (!getInputProps().accept.includes(ext))
                                getErrorNotificationMessage(t("file.invalid"));
                              else
                                getErrorNotificationMessage(t("file.single"))
                            },
                          })}
                        >
                          <input name="file" {...getInputProps()} />
                          <div className="onboarding-document-item">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="35"
                              height="35"
                              fill="none"
                              viewBox="0 0 37 38"
                            >
                              <g
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.525"
                                clipPath="url(#clip0_455_5823)"
                              >
                                <path
                                  stroke="#048A89"
                                  strokeOpacity="1"
                                  d="M24.792 24.871l-6.1-6.099-6.098 6.1"
                                ></path>
                                <path
                                  stroke="#048A89"
                                  d="M18.691 18.772v13.723M31.485 28.515a7.624 7.624 0 00-3.644-14.317H25.92a12.198 12.198 0 10-20.95 11.13"
                                ></path>
                                <path
                                  stroke="#048A89"
                                  d="M24.792 24.871l-6.1-6.099-6.098 6.1"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_455_5823">
                                  <path
                                    fill="#fff"
                                    d="M0 0H36.594V36.594H0z"
                                    transform="translate(.396 .475)"
                                  ></path>
                                </clipPath>
                              </defs>
                            </svg>
                            <p>
                              {t("file.placeholder")} <br></br>{" "}
                              {t("file.supported_bulk")} <br></br> {t("file.drag")}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    <ErrorMessage
                      name="import_excel_file"
                      component={"div"}
                      className="errorMsg w-100x"
                    />
                    {files &&
                      files.map((file, index) => (
                        <div className="upload-preview-sec" key={file.name}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 18 22"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.0297 5.76641C17.1703 5.90703 17.25 6.09687 17.25 6.29609V20.75C17.25 21.1648 16.9148 21.5 16.5 21.5H1.5C1.08516 21.5 0.75 21.1648 0.75 20.75V1.25C0.75 0.835156 1.08516 0.5 1.5 0.5H11.4539C11.6531 0.5 11.8453 0.579688 11.9859 0.720313L17.0297 5.76641ZM15.5203 6.64062L11.1094 2.22969V6.64062H15.5203ZM4.5 10.2969C4.45027 10.2969 4.40258 10.3166 4.36742 10.3518C4.33225 10.387 4.3125 10.4346 4.3125 10.4844V11.6094C4.3125 11.6591 4.33225 11.7068 4.36742 11.742C4.40258 11.7771 4.45027 11.7969 4.5 11.7969H13.5C13.5497 11.7969 13.5974 11.7771 13.6326 11.742C13.6677 11.7068 13.6875 11.6591 13.6875 11.6094V10.4844C13.6875 10.4346 13.6677 10.387 13.6326 10.3518C13.5974 10.3166 13.5497 10.2969 13.5 10.2969H4.5ZM4.5 13.4844C4.45027 13.4844 4.40258 13.5041 4.36742 13.5393C4.33225 13.5745 4.3125 13.6221 4.3125 13.6719V14.7969C4.3125 14.8466 4.33225 14.8943 4.36742 14.9295C4.40258 14.9646 4.45027 14.9844 4.5 14.9844H8.8125C8.86223 14.9844 8.90992 14.9646 8.94508 14.9295C8.98025 14.8943 9 14.8466 9 14.7969V13.6719C9 13.6221 8.98025 13.5745 8.94508 13.5393C8.90992 13.5041 8.86223 13.4844 8.8125 13.4844H4.5Z"
                              fill="#048A89"
                            />
                          </svg>
                          <div className="w-100">
                            <p className="file-upload-name">{file.name}</p>
                            <span className="file-upload-size">
                              {file.size >= 1024
                                ? (file.size / (1024 * 1024)).toFixed(2) + " MB"
                                : (file.size / 1024).toFixed(2) + " KB"}
                            </span>
                          </div>
                          <div
                            onClick={() => {
                              removeFile(index);
                              setFieldValue("import_excel_file", null);
                            }}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1ZM8 7.151L6.374 5.525C6.26142 5.41242 6.10872 5.34917 5.9495 5.34917C5.79028 5.34917 5.63758 5.41242 5.525 5.525C5.41242 5.63758 5.34917 5.79028 5.34917 5.9495C5.34917 6.10872 5.41242 6.26142 5.525 6.374L7.151 8L5.525 9.626C5.46925 9.68175 5.42503 9.74793 5.39486 9.82076C5.36469 9.8936 5.34917 9.97166 5.34917 10.0505C5.34917 10.1293 5.36469 10.2074 5.39486 10.2802C5.42503 10.3531 5.46925 10.4193 5.525 10.475C5.58075 10.5307 5.64693 10.575 5.71976 10.6051C5.7926 10.6353 5.87066 10.6508 5.9495 10.6508C6.02834 10.6508 6.1064 10.6353 6.17924 10.6051C6.25207 10.575 6.31825 10.5307 6.374 10.475L8 8.849L9.626 10.475C9.68175 10.5307 9.74793 10.575 9.82076 10.6051C9.8936 10.6353 9.97166 10.6508 10.0505 10.6508C10.1293 10.6508 10.2074 10.6353 10.2802 10.6051C10.3531 10.575 10.4193 10.5307 10.475 10.475C10.5307 10.4193 10.575 10.3531 10.6051 10.2802C10.6353 10.2074 10.6508 10.1293 10.6508 10.0505C10.6508 9.97166 10.6353 9.8936 10.6051 9.82076C10.575 9.74793 10.5307 9.68175 10.475 9.626L8.849 8L10.475 6.374C10.5307 6.31825 10.575 6.25207 10.6051 6.17924C10.6353 6.1064 10.6508 6.02834 10.6508 5.9495C10.6508 5.87066 10.6353 5.7926 10.6051 5.71976C10.575 5.64693 10.5307 5.58075 10.475 5.525C10.4193 5.46925 10.3531 5.42503 10.2802 5.39486C10.2074 5.36469 10.1293 5.34917 10.0505 5.34917C9.97166 5.34917 9.8936 5.36469 9.82076 5.39486C9.74793 5.42503 9.68175 5.46925 9.626 5.525L8 7.151Z"
                                fill="#048A89"
                              />
                            </svg>
                          </div>
                        </div>
                    ))}
                    <p className="mt-3">{t("sample_payout_request")} : <span>
                      {[PAY_OUT_TYPE_ZYRO, PAY_OUT_TYPE_AXIS, PAY_OUT_TYPE_HAODA_BANK, PAY_OUT_TYPE_V2C, PAY_OUT_TYPE_DIASPAY].includes(props.payoutType) ? <a href={window.location.origin + "/SamplePayoutBank.xlsx"} download>
                      {t("download")}
                    </a> : <a href={window.location.origin + "/SamplePayoutUPI.xlsx"} download>
                      {t("download")}
                    </a>}
                    </span></p>
                  </Form.Group>
                    <div className="request-payout-payment-method-btn-sec">
                      <Button
                        className="snapgopay-cancel-btn"
                        onClick={() => props.closeWithdrawalModal()}
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        type="submit"
                        className="snapgopay-auth-btn"
                        disabled={importrequestPayOut.buttonDisable}
                      >
                        {importrequestPayOut.buttonDisable ? (
                          <ButtonLoader />
                        ) : (
                          t("payment_btn.text")
                        )}
                      </Button>
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  );
};

export default WithdrawalRequest;





